import React, { useState, useEffect } from 'react';
import UserTable from './UserTable';
import { urlPrefix } from '../../../../Settings';

// import FilterModal from './filter/FilterForUsers'; // Ensure this component exists
import { FaFilter, FaCheckCircle, FaClock, FaTimesCircle } from 'react-icons/fa';

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // Fetch data when component mounts
  useEffect(() => {
    const fetchUsers = async (statusId = null, firstName = '', lastName = '') => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error('No token found');
        return;
      }
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      try {
        const url = `${urlPrefix}/User/filter?statusIds=1&statusIds=2&accountId=${1}`;
        const response = await fetch(url, requestOptions)
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setUsersData(data);
        setFilteredUsers(data); 
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers(2);
  }, []);

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleFilter = ({ startDate, endDate }) => {
    let filtered = usersData;

    if (startDate) {
      filtered = filtered.filter(user => new Date(user.CreateDate) >= new Date(startDate));
    }

    if (endDate) {
      filtered = filtered.filter(user => new Date(user.CreateDate) <= new Date(endDate));
    }

    setFilteredUsers(filtered);
  };

  return (
    <div className="bg-[#ffffff] rounded-lg shadow-md p-4 md:p-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search for user"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-3 py-2 border border-[#d1d5db] rounded-md w-1/3"
        />
        <button
          onClick={() => setIsFilterModalOpen(true)}
          className="flex items-center px-3 py-2 bg-[#2563eb] text-white rounded-md shadow-md"
        >
          <FaFilter className="mr-2" />
          Filter
        </button>
      </div>

      <UserTable
        users={filteredUsers}
        searchTerm={searchTerm}
        dropdownOpen={dropdownOpen}
        toggleDropdown={toggleDropdown}
        isArchived={false}
      />

      {/* <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        onFilter={handleFilter}
      /> */}
    </div>
  );
};

export default Users;
