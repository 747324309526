import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../Settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const AllAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [userAccounts, setUserAccounts] = useState([]); // Track user joined accounts
  const [visibleCount, setVisibleCount] = useState(18);
  const [joiningStatus, setJoiningStatus] = useState({}); // Track joining status for each account
  const [userId, setUserId] = useState(null);
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState('');     // State for error message
  const navigate = useNavigate();
  const messageTimeoutRef = useRef(null); // Ref to store the timeout

  // Fetch accounts when the component mounts
  const fetchAccounts = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/exclude-admins`, requestOptions);
      const data = await response.json();

      if (data && data.length > 0) {
        setAccounts(data);
      } else {
        setErrorMessage('No accounts found or data format is incorrect.');
        hideMessagesAfterTimeout();
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setErrorMessage('Error fetching accounts. Please try again.');
      hideMessagesAfterTimeout();
    }
  };

  const fetchUserAccounts = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/users/accounts`, requestOptions);
      const data = await response.json();

      if (data && data.length > 0) {
        setUserAccounts(data);
      } else {
        setErrorMessage('No user accounts found or data format is incorrect.');
        hideMessagesAfterTimeout();
      }
    } catch (error) {
      console.error('Error fetching user accounts:', error);
      setErrorMessage('Error fetching user accounts. Please try again.');
      hideMessagesAfterTimeout();
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(JSON.parse(storedUserId));
    }

    fetchAccounts();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserAccounts();
    }
  }, [userId]);

  const handleJoinClick = async (account) => {
    const accountId = account.id;

    if (!userId) {
      setErrorMessage('User not logged in.');
      hideMessagesAfterTimeout();
      return;
    }

    setJoiningStatus((prevStatus) => ({
      ...prevStatus,
      [accountId]: true, // Set the loading state for the clicked account
    }));

    const payload = {
      userId: parseInt(userId, 10),
      accountId,
      userAccountRoleEnum: 8,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/visible`, requestOptions);

      if (response.ok) {
        setSuccessMessage('Successfully joined the account!');
        // Add the account to the userAccounts state
        setUserAccounts((prevAccounts) => [...prevAccounts, account]);
        hideMessagesAfterTimeout();
      } else {
        setErrorMessage('Failed to join account. Please try again.');
        hideMessagesAfterTimeout();
      }
    } catch (error) {
      console.error('Error joining account:', error);
      setErrorMessage('An error occurred. Please try again.');
      hideMessagesAfterTimeout();
    } finally {
      setJoiningStatus((prevStatus) => ({
        ...prevStatus,
        [accountId]: false, // Reset the loading state for the clicked account
      }));
    }
  };

  const hideMessagesAfterTimeout = () => {
    if (messageTimeoutRef.current) {
      clearTimeout(messageTimeoutRef.current);
    }

    messageTimeoutRef.current = setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 3000); // Hide messages after 3 seconds
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 18);
  };

  const handleShowLess = () => {
    setVisibleCount(18);
  };

  return (
    <div className="mt-32 mb-5 px-4">
      {/* Back to Home Button */}
      <div className="flex justify-start mb-4 mt-5">
        <button
          onClick={() => navigate('/user')}
          className="flex items-center text-blue-600 hover:text-blue-800 font-semibold text-lg cursor-pointer"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" /> Back
        </button>
      </div>

      <h1 className="flex text-4xl text-sky-900 ml-3 mb-3">All Accounts</h1>

      {/* Success or Error Message */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            {successMessage}
          </span>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {errorMessage}
          </span>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
        {accounts.slice(0, visibleCount).map((account, index) => (
          <div
            className="p-1 h-full w-full rounded-lg shadow-md hover:shadow-xl flex flex-col justify-between transition-all duration-300 transform hover:-translate-y-2 hover:bg-slate-300 cursor-pointer border border-solid border-blue-950"
            key={index}
          >
            <div className="flex-grow">
              <div
                className="mb-1 w-full h-40 bg-cover bg-center rounded"
                style={{ backgroundImage: `url(${account.accountImage || ''})` }}
              ></div>
              <div className="bg-slate-800 rounded">
                <div className="flex flex-col items-start px-2 pb-3 pt-1">
                  <h3 className="text-xl font-bold mb-1 text-white">{account.name}</h3>
                  {account.description && <p className="text-sm text-white py-2">{account.description}</p>}
                </div>
                {/* <div className="flex justify-end items-stretch -mt-6 p-2">
                  {userAccounts.some((userAccount) => userAccount.id === account.id) ? (
                    <button
                      className="btn mt-4 bg-green-500 text-white px-3 py-2 rounded-lg shadow-md flex justify-center items-center cursor-not-allowed"
                      disabled
                    >
                      Enrolled
                    </button>
                  ) : (
                    <button
                      onClick={() => handleJoinClick(account)}
                      className={`btn mt-4 text-white px-3 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out flex justify-center items-center 
                        ${joiningStatus[account.id] ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 hover:shadow-lg'}`}
                      disabled={joiningStatus[account.id]}
                    >
                      {joiningStatus[account.id] ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Join'
                      )}
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-4">
        {visibleCount < accounts.length && (
          <button
            onClick={handleShowMore}
            className="px-4 py-2 text-blue-600 border border-blue-600 rounded hover:bg-blue-600 hover:text-white transition-all duration-300 ease-in-out"
          >
            Show More
          </button>
        )}
        {visibleCount > 18 && (
          <button
            onClick={handleShowLess}
            className="px-4 py-2 text-blue-600 border border-blue-600 rounded hover:bg-blue-600 hover:text-white transition-all duration-300 ease-in-out"
          >
            Show Less
          </button>
        )}
      </div>
    </div>
  );
};

export default AllAccounts;
