import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation if you need it
import ClassInfo from './ClassInfo';
import { urlPrefix } from '../../../../../Settings';

const EditClass = ({ accountId }) => {  // Note: destructuring `accountId` from props
    const location = useLocation(); // Use location to access state, if needed
    const [accountData, setAccountData] = useState(null);
    const [classData, setClassData] = useState(location.state?.classItem || null);

    // Function to modify the account (PUT request)
    const editAccount = async (courseData) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error('No token found');
            return;
        }

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                accountImage: courseData.photoURL ? courseData.photoURL : null,
                name: courseData.classTitle,
                description: courseData.classDescription,
                maxCapacity: courseData.classCapacity,
                onboardingLink: courseData.classLink.url,
                isVisible: courseData.classVisible,
                statusId: accountData.statusId,  // Ensure this value is present
                accountId: accountId // Passed from props
            })
        };

        try {
            const url = `${urlPrefix}/Account/modifyAccount?accountId=${classData.id}`;
            const response = await fetch(url, requestOptions);

            if (!response.ok) {
                throw new Error('Failed to edit account');
            }

            const result = await response.json();
            console.log('Account edited successfully:', result);
        } catch (error) {
            console.error('Error editing account:', error);
        }
    };

    // Fetch class info on component mount
    const fetchClassInfo = async (accountId) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error('No token found');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            }
        };

        try {
            const response = await fetch(`${urlPrefix}/Account/info/${accountId}`, requestOptions);
            if (!response.ok) {
                throw new Error(`Error fetching class info: ${response.statusText}`);
            }

            const data = await response.json();
            setAccountData(data);
        } catch (error) {
            console.error('Error fetching class info:', error);
        }
    };

    useEffect(() => {
        fetchClassInfo(accountId); // Fetch class info when accountId changes or on mount
    }, [accountId]);

    if (!accountData) {
        return <div>Loading...</div>; // Display a loading indicator while fetching
    }

    return (
        <div>
            <ClassInfo
                initialImage={accountData.accountImage}  // Initialize with fetched data
                initialTitle={accountData.name}
                initialDescription={accountData.description}
                initialLink={{ url: accountData.onboardingLink, label: '' }}
                initialCapacity={accountData.maxCapacity}
                initialVisibility={accountData.isVisible}
                handleAction={editAccount}  // Pass edit function to ClassInfo
            />
        </div>
    );
};

export default EditClass;
