import React from 'react';
import AboutUsPage from './AboutUsPage'; // Import the AboutUs component
import Navbar from '../../Navbar';  // Import the Navbar component
import Footer from '../../Footer';  // Import the Footer component

const AboutUs = () => {
  return (
    <div>
      <Navbar /> {/* Include Navbar at the top */}
      <AboutUsPage /> {/* Main AboutUs section */}
      <Footer /> {/* Include Footer at the bottom */}
    </div>
  );
};

export default AboutUs;