import React from 'react';
import ClassRow from './ClassRow';

const ClassTable = ({ classesData, isArchived = false, dropdownOpen, toggleDropdown, }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-4 px-8 border-b text-lg">Photo</th>
            <th className="py-4 px-8 border-b text-lg">Name</th>
            <th className="py-4 px-8 border-b text-lg">Status</th>
            <th className="py-4 px-8 border-b text-lg">Visible</th>
            <th className="py-4 px-8 border-b text-lg">Capacity</th>
            <th className="py-4 px-8 border-b text-lg">Date Created</th>
            {isArchived && (
              <th className="py-4 px-8 border-b text-lg">Date Deleted</th>
            )}
            <th className="py-4 px-8 border-b text-lg">Action</th>
          </tr>
        </thead>
        <tbody>
          {classesData.map((classItem, i) => (
            <ClassRow 
              classItem={classItem}
              dropdownOpen={dropdownOpen}
              toggleDropdown={toggleDropdown}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassTable;
