import React, { useState, useEffect } from 'react';
import UserTable from './UserTable';
import { FaTimesCircle } from 'react-icons/fa';
import { urlPrefix } from '../../../../Settings';

const ArchivedUsers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async (statusId = null, firstName = '', lastName = '') => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error('No token found');
        return;
      }
      console.log(token)

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      try {
        const url = `${urlPrefix}/User/filter?statusIds=3&accountId=${1}`;
        const response = await fetch(url, requestOptions)
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        setFilteredUsers(data); 
      } catch (error) {
        console.error('Error fetching users:', error);
        setError(error.message);
      }
    };

    fetchUsers(3);
  }, []); // Empty dependency array means this will run once when the component mounts

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const statusIcon = (status) => {
    switch (status) {
      case 'Deleted':
        return <FaTimesCircle className="text-[#ef4444]" />;
      default:
        return null;
    }
  };

  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bg-[#ffffff] rounded-lg shadow-md p-4 md:p-6">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search for user"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-3 py-2 border border-[#d1d5db] rounded-md w-1/3"
        />
      </div>

      <UserTable
        users={filteredUsers}
        searchTerm={searchTerm}
        dropdownOpen={dropdownOpen}
        toggleDropdown={toggleDropdown}
        statusIcon={statusIcon}
        isArchived={true}
      />
    </div>
  );
};

export default ArchivedUsers;
