import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './Main';
import NavBar from '../Navbar';
import Footer from '../Footer';
import LearnMoreDiscoverMore from './LearnMoreDiscoverMore';
import LearnMoreBenefit from './LearnMoreBenefit';
import LearnMoreCaseStudy from './LearnMoreCaseStudy';
import LearnMoreFeature from './LearnMoreFeature';
import ContactUs from './ContactUs';

function Landing() {
  return (
    <div>
      <NavBar /> {/* Including Navbar at the top */}
      
      {/* Main content or nested routes */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/learn-more-discover-more" element={<LearnMoreDiscoverMore />} />
        <Route path="/learn-more-benefit/:id" element={<LearnMoreBenefit />} />
        <Route path="/learn-more-case-study/:id" element={<LearnMoreCaseStudy />} />
        <Route path="/learn-more-feature/:id" element={<LearnMoreFeature />} />
      </Routes>
      <ContactUs/>
      <Footer /> {/* Including Footer at the bottom */}
    </div>
  );
}

export default Landing;
