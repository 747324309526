import React from 'react';

const JoinFormModal = ({ account, onClose, formUrl }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl relative">
        <button 
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900" 
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4">Join {account.name}</h2>
        <div className="mb-4">
          <iframe 
            src={formUrl} 
            width="100%" 
            height="500" 
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0"
            title="Join Form"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  );
};

export default JoinFormModal;
