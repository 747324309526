// UserDashboard.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import UserConnectedAccounts from './userConnectedAccounts/UserConnectedAccounts';
import MostPopularAccounts from './popularAccounts/MostPopularAccounts';
import AllAccounts from './AllAccounts';
import Navbar from '../Navbar';
import Footer from '../Footer';
import EditUser from './EditUser';
import ContactUs from '../landing/ContactUs';

const UserDashboard = () => {
  const [allAccounts, setAllAccounts] = useState([]);  // Store all accounts here

  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <Navbar />
      
      <div className="flex-1">
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <div className='user-connected-accounts mt-32 mx-4'>
                  <UserConnectedAccounts />
                </div>
                <div className="most-popular-accounts mt-16 mx-4 mb-5">
                  <MostPopularAccounts setAllAccounts={setAllAccounts} />
                </div>
              </>
            } 
          />
          <Route 
            path="/all-accounts" 
            element={<AllAccounts accounts={allAccounts} />} 
          />
           <Route 
            path="/edit-profile" 
            element={<EditUser />} 
          />
        </Routes>
      </div>
      <ContactUs/>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UserDashboard;