import React, { useState } from 'react';

const ConfigureUi = () => {
  const [theme, setTheme] = useState('light');
  const [layout, setLayout] = useState('default');
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [primaryColor, setPrimaryColor] = useState('#007bff');
  const [fontSize, setFontSize] = useState('medium');

  const handleSave = () => {
    // Implement save logic here
    console.log('Settings saved:', { theme, layout, showSidePanel, primaryColor, fontSize });
    alert('UI settings saved!');
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Configure UI</h2>
      <form className="space-y-6">
        <div className="flex flex-col">
          <label htmlFor="theme" className="text-gray-700">Select Theme</label>
          <select
            id="theme"
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            className="mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="light">Light</option>
            <option value="dark">Dark</option>
            <option value="system">System Default</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="layout" className="text-gray-700">Select Layout</label>
          <select
            id="layout"
            value={layout}
            onChange={(e) => setLayout(e.target.value)}
            className="mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="default">Default</option>
            <option value="compact">Compact</option>
            <option value="spacious">Spacious</option>
          </select>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="showSidePanel"
            checked={showSidePanel}
            onChange={(e) => setShowSidePanel(e.target.checked)}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
          <label htmlFor="showSidePanel" className="ml-2 text-gray-700">Show Side Panel</label>
        </div>
        <div className="flex flex-col">
          <label htmlFor="primaryColor" className="text-gray-700">Primary Color</label>
          <input
            type="color"
            id="primaryColor"
            value={primaryColor}
            onChange={(e) => setPrimaryColor(e.target.value)}
            className="mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="fontSize" className="text-gray-700">Font Size</label>
          <select
            id="fontSize"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            className="mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConfigureUi;
