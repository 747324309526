import React from 'react';
import Hero from './Hero';
import Features from './Features';
import Benefits from './Benefits';
import CardGrid from './CaseStudy';
import DiscoverMore from './DiscoverMore';
import ContactUs from './ContactUs';

const Main = () => {
  return (
    <main id="content" role="main">
      <Hero />
      <div style={{ borderBottom: '2px solid #ccc', borderRadius:"5px", paddingBottom: '20px' }}>
        <Features />
      </div>

      <div style={{ borderBottom: '2px solid #ccc', borderRadius:"5px", paddingBottom: '20px' }}>
        <Benefits />
      </div>

      <div style={{ borderBottom: '2px solid #ccc', borderRadius:"5px", paddingBottom: '20px' }}>
        <CardGrid />
      </div>

      <div style={{ borderBottom: '2px solid #ccc', borderRadius:"5px", paddingBottom: '20px' }}>
        <DiscoverMore />
      </div>
    </main>
  );
};

export default Main;