import React, { useState } from 'react';
import { urlPrefix } from "../Settings"; // Ensure the URL prefix is correctly set

const ResetPasswordModal = ({ showModal, handleClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Email: email,
      }),
    };

    await fetch(`${urlPrefix}/User/resetPassword`, requestOptions)
      .then((response) => {
        if (response.ok) {
          setMessage("Check your email for reset instructions.");
        } else {
          setMessage("Failed to send reset email. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMessage("An error occurred. Please try again.");
      });
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${showModal ? '' : 'hidden'}`} tabIndex="-1" aria-labelledby="resetPasswordModalLabel" aria-hidden={!showModal}>
      <div className="max-w-lg w-full mx-4">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg">
          <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
            <div className="flex items-center">
              <button type="button" className="text-lg font-semibold text-gray-700 hover:text-gray-900" onClick={handleClose}>Back</button>
            </div>
            <h5 className="text-lg font-semibold" id="resetPasswordModalLabel">Reset Password</h5>
          </div>
          <div className="px-6 py-4">
            {message ? (
              <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                <p className="font-bold">{message}</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="resetPasswordEmail" className="block text-sm font-medium text-gray-700">Your email</label>
                  <input
                    type="email"
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="resetPasswordEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Submit</button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
