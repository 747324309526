import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../Settings';

const NavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState({});
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const fetchUser = async () => {
    if (!userId) return;

    try {
      const response = await fetch(`${urlPrefix}/User/${userId}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (data) {
        setUser(data);
      } else {
        console.error("Unexpected data format:", data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setIsLoggedIn(true);
      setUserId(JSON.parse(storedUserId));
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/');  // Navigate to the landing page on logout
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-gradient-to-b from-[#212121] to-[#4a4a4a] text-[#ffffff] p-4 md:p-6 shadow-md flex items-center justify-between">
      <h1 className="text-xl md:text-2xl font-semibold tracking-wide">Learning Platform Admin</h1>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            className="w-24 md:w-40 px-2 py-1 md:px-4 md:py-2 rounded-full bg-[#ffffff] text-[#000000] focus:outline-none focus:ring focus:border-[#9d9d9d]"
          />
          <FaSearch className="w-4 h-4 md:w-5 md:h-5 absolute right-2 md:right-4 top-1.5 md:top-2.5 text-[#b0b0b0]" />
        </div>
        {isLoggedIn && (
          <div className="relative flex items-center space-x-3">
            <img
              src={user.userImage || 'https://via.placeholder.com/150'}
              alt="Profile"
              className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-[#7d7d7d] cursor-pointer"
              onClick={toggleDropdown}
            />
            <span className="text-sm md:text-lg text-white cursor-pointer">{user.firstName}</span>
            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 mt-28 w-36 md:w-48 bg-[#2c2c2c] rounded-md shadow-lg py-2 z-20"
              >
                <a
                  className="flex items-center px-4 py-2 text-[#cccccc] hover:bg-[#4b4b4b] transition duration-200 cursor-pointer"
                  onClick={handleLogout}
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;