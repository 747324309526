import React from 'react';
import { FaEye, FaEyeSlash, FaCheckCircle, FaEllipsisV, FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ClassRow = ({ classItem, dropdownOpen, toggleDropdown }) => {
  const navigate = useNavigate(); // Initialize navigate

  const handleRowClick = () => {
    // Navigate to the ClassView page with class ID or other relevant data
    navigate(`/admin/class-view/${classItem.id}`, { state: { classItem } });
  };

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent row click event
    // Navigate to the EditClass page with class ID
     // Check the classItem object
    navigate(`/admin/edit-class/${classItem.id}`, { state: { classItem } });
};

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent row click event
    // Navigate to the DeleteClass page with class ID
    navigate(`/admin/delete-class/${classItem.id}`, { state: { classItem } });
  };
  

  return (
    <tr className="bg-[#FFFFFF] shadow-lg rounded-lg relative cursor-pointer" onClick={handleRowClick}>
      <td className="py-4 px-8 border-b border-[#E5E7EB]">
        <div>
          <img src={classItem.accountImage} alt={classItem.name} className="w-12 h-12 rounded-full shadow-md" />
        </div>
      </td>
      <td className="py-4 px-8 border-b border-[#E5E7EB]">
        <div className="flex text-lg font-semibold text-[#000000]">
          <span>{classItem.name}</span>
        </div>
      </td>
      <td className="py-4 px-8 border-b border-[#E5E7EB]">
        <div className="flex items-center text-lg font-semibold text-[#000000]">
          {classItem.statusId === 2 ? <FaCheckCircle className="text-[#00f100] mr-2" /> : null}
          <span>{classItem.statusId === 2 ? "Active" : "Pending"}</span>
        </div>
      </td>
      <td className="py-4 px-8 border-b border-[#E5E7EB]">
        <div className="flex items-center text-lg font-semibold text-[#000000]">
          {classItem.isVisible ? <FaEye className="text-[#21fb00]" /> : <FaEyeSlash className="text-[#ff0000]" />}
          <span className="ml-2">{classItem.isVisible ? 'Yes' : 'No'}</span>
        </div>
      </td>
      <td className="py-4 px-5 border-b border-[#E5E7EB]">
        <div className="flex items-center justify-center h-full text-lg font-semibold text-[#000000]">
          <span>{classItem.maxCapacity}</span>
        </div>
      </td>
      <td className="py-4 px-8 border-b border-[#E5E7EB]">
        <div className="text-lg font-semibold text-[#000000]">
          <span>{new Date(classItem.dateCreated).toLocaleDateString()}</span>
        </div>
      </td>
      <td className="py-4 px-8 border-b border-[#E5E7EB] relative">
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click event
            toggleDropdown(classItem.id);
          }}
          className="focus:outline-none text-[#6B7280] hover:text-[#374151] transition duration-200">
          <FaEllipsisV />
        </button>

        {dropdownOpen === classItem.id && (
          <div className="absolute top-12 right-0 bg-white border border-[#D1D5DB] shadow-lg rounded-lg p-2 z-10">
            <button
              onClick={handleEdit}
              className="flex items-center px-4 py-2 w-full text-left text-[#000000] hover:bg-[#F3F4F6]">
              <FaEdit className="mr-2" /> Edit
            </button>
            <button
              onClick={handleDelete}
              className="flex items-center px-4 py-2 w-full text-left text-[#000000] hover:bg-[#F3F4F6]">
              <FaTrash className="mr-2 text-red-500" /> Delete
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ClassRow;
