import React, { useState } from 'react';

const NotificationSettings = () => {
  const [settings, setSettings] = useState({
    emailNotifications: true,
    smsNotifications: false,
    pushNotifications: true,
    weeklySummary: true,
    specialOffers: false,
  });

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setSettings({ ...settings, [name]: checked });
  };

  const handleSave = () => {
    // Implement save logic here
    console.log('Settings saved:', settings);
    alert('Notification settings saved!');
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Notification Settings</h2>
      <form className="space-y-6">
        <div className="flex items-center justify-between">
          <label htmlFor="emailNotifications" className="text-gray-700">Email Notifications</label>
          <input
            type="checkbox"
            id="emailNotifications"
            name="emailNotifications"
            checked={settings.emailNotifications}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="smsNotifications" className="text-gray-700">SMS Notifications</label>
          <input
            type="checkbox"
            id="smsNotifications"
            name="smsNotifications"
            checked={settings.smsNotifications}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="pushNotifications" className="text-gray-700">Push Notifications</label>
          <input
            type="checkbox"
            id="pushNotifications"
            name="pushNotifications"
            checked={settings.pushNotifications}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="weeklySummary" className="text-gray-700">Weekly Summary</label>
          <input
            type="checkbox"
            id="weeklySummary"
            name="weeklySummary"
            checked={settings.weeklySummary}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex items-center justify-between">
          <label htmlFor="specialOffers" className="text-gray-700">Special Offers</label>
          <input
            type="checkbox"
            id="specialOffers"
            name="specialOffers"
            checked={settings.specialOffers}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default NotificationSettings;
