import React, { useEffect, useState } from 'react';
import { urlPrefix } from '../../../Settings';
import { Link, useNavigate } from 'react-router-dom';

const UserConnectedAccounts = ({ setActivePage }) => {
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(6);
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const fetchConnectedAccounts = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/users/accounts`, requestOptions);
      const data = await response.json();
      setConnectedAccounts(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching connected accounts:', error);
    }
  };

  const handleAccountLogin = async (accountId) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/login?accountId=${accountId}`, requestOptions);
      if (response.ok) {
        // After successful login, set active page to account-dashboard
        setActivePage('account-dashboard');
        localStorage.setItem('selectedAccountId', accountId);  // Optionally store the selected accountId
      } else {
        console.error('Error logging in to the account:', response.statusText);
      }
    } catch (error) {
      console.error('Error during account login:', error);
    }
  };

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 6);
  };

  const handleShowLess = () => {
    setVisibleCount(6);
    setIsExpanded(false);
  };

  const getAccountRole = (roleId) => {
    switch (roleId) {
      case 1:
        return 'SysAdmin';
      case 2:
        return 'Admin';
      case 4:
        return 'AccountManager';
      case 8:
        return 'Beneficiary';
      default:
        return 'Unknown';
    }
  };

  return (
    <div className='mt-16 mb-1'>
      <h1 className='flex text-4xl text-sky-900 ml-3 mb-3'>Enrolled Classes</h1>
      {connectedAccounts.length === 0 ? (
        <h1 className="text-center text-2xl text-gray-500">No accounts found.</h1>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {connectedAccounts.slice(0, visibleCount).map((account, index) => {
            return (
              <div 
                key={index}
                // onClick={() => handleAccountLogin(account.id)}
                className="p-1 h-full w-full rounded-lg shadow-md hover:shadow-xl flex flex-col justify-between transition-transform duration-300 transform hover:-translate-y-2 hover:bg-slate-300 cursor-pointer border border-solid border-blue-950"
              >
                <div className="flex-grow">
                  <div 
                    className="mb-1 w-full h-40 bg-cover bg-center rounded" 
                    style={{ backgroundImage: `url(${account.accountImage || ''})` }}
                  ></div>
                  <div className='bg-slate-800 rounded'>
                    <div className="flex flex-col items-start px-2">
                      <h3 className="text-xl font-bold mb-1 text-white">{account.name}</h3>
                      {account.description && <p className="text-sm text-white">{account.description}</p>}
                    </div>
                    <div className="flex justify-end items-center p-2">
                      <span className="flex justify-end items-center mt-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full">{getAccountRole(account.accountRoleId)}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex mt-4">
        {connectedAccounts.length > visibleCount && (
          <button onClick={handleShowMore} className="btn btn-outline-primary mr-6">Show More Accounts</button>
        )}
        {visibleCount > 6 && (
          <button onClick={handleShowLess} className="btn btn-outline-primary">Show Less Accounts</button>
        )}
      </div>
    </div>
  );
};

export default UserConnectedAccounts;



// import React, { useEffect, useState } from 'react';
// import { urlPrefix } from '../../../Settings';
// import { Link, useNavigate } from 'react-router-dom';

// const UserConnectedAccounts = () => {
//   const [connectedAccounts, setConnectedAccounts] = useState([]);
//   const [visibleCount, setVisibleCount] = useState(6);
//   const [isExpanded, setIsExpanded] = useState(false);
//   const navigate = useNavigate();

//   const fetchConnectedAccounts = async () => {
//     const token = localStorage.getItem("token");
//     if (!token) return;

//     const requestOptions = {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         "Authorization": `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await fetch(`${urlPrefix}/Account/users/accounts`, requestOptions);
//       const data = await response.json();
//       setConnectedAccounts(Array.isArray(data) ? data : []);
//     } catch (error) {
//       console.error('Error fetching connected accounts:', error);
//     }
//   };

//   const handleAccountLogin = async (accountId) => {
//     const token = localStorage.getItem("token");
//     if (!token) return;

//     const requestOptions = {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         "Authorization": `Bearer ${token}`
//       }
//     };

//     try {
//       const response = await fetch(`${urlPrefix}/Account/login?accountId=${accountId}`, requestOptions);
//       if (response.ok) {
//         navigate(`/accountDashboard?accountId=${accountId}`);
//       } else {
//         console.error('Error logging in to the account:', response.statusText);
//       }
//     } catch (error) {
//       console.error('Error during account login:', error);
//     }
//   };

//   useEffect(() => {
//     fetchConnectedAccounts();
//   }, []);

//   const handleShowMore = () => {
//     setVisibleCount(prevCount => prevCount + 6);
//   };

//   const handleShowLess = () => {
//     setVisibleCount(6);
//     setIsExpanded(false);
//   };

//   const getAccountRole = (roleId) => {
//     switch (roleId) {
//       case 1:
//         return 'SysAdmin';
//       case 2:
//         return 'Admin';
//       case 4:
//         return 'AccountManager';
//       case 8:
//         return 'Beneficiary';
//       default:
//         return 'Unknown';
//     }
//   };

//   return (
//     <div className='mt-16 mb-1'>
//       <h1 className='flex text-4xl text-sky-900 ml-3 mb-3'>Enrolled Accounts</h1>
//       {connectedAccounts.length === 0 ? (
//         <h1 className="text-center text-2xl text-gray-500">No accounts found.</h1>
//       ) : (
//         <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
//           {connectedAccounts.slice(0, visibleCount).map((account, index) => {
//             return (
//               <div 
//                 key={index}
//                 onClick={() => handleAccountLogin(account.id)}
//                 className="p-1 h-full w-full rounded-lg shadow-md hover:shadow-xl flex flex-col justify-between transition-transform duration-300 transform hover:-translate-y-2 hover:bg-slate-300 cursor-pointer border border-solid border-blue-950"
//               >
//                 <div className="flex-grow">
//                   <div 
//                     className="mb-1 w-full h-40 bg-cover bg-center rounded" 
//                     style={{ backgroundImage: `url(${account.accountImage || ''})` }}
//                   ></div>
//                   <div className='bg-slate-800 rounded'>
//                     <div className="flex flex-col items-start px-2">
//                       <h3 className="text-xl font-bold mb-1 text-white">{account.name}</h3>
//                       {account.description && <p className="text-sm text-white">{account.description}</p>}
//                     </div>
//                     <div className="flex justify-end items-center p-2">
//                       <span className="flex justify-end items-center mt-2 bg-blue-600 text-white text-xs px-2 py-1 rounded-full">{getAccountRole(account.accountRoleId)}</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//       <div className="flex mt-4">
//         {connectedAccounts.length > visibleCount && (
//           <button onClick={handleShowMore} className="btn btn-outline-primary mr-6">Show More Accounts</button>
//         )}
//         {visibleCount > 6 && (
//           <button onClick={handleShowLess} className="btn btn-outline-primary">Show Less Accounts</button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default UserConnectedAccounts;