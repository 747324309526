import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

const EmailUpdateModal = ({ isOpen, onClose, modalData, setModalData, userEmail, errors, setErrors, userId, urlPrefix }) => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            // Reset modal fields when the modal is opened
            setModalData({ currentEmail: '', newEmail: '', password: '' });
            setErrors({});
            setMessage('');
            setLoading(false); // Reset loading state when modal opens
        }
    }, [isOpen]);

    const validateEmail = (email) => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 6; // Example: Password must be at least 6 characters long
    };

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalData({ ...modalData, [name]: value });

        let newErrors = { ...errors };

        // Validate each field based on its name
        if (name === 'currentEmail') {
            if (value !== userEmail && value === modalData.newEmail) {
                newErrors.currentEmail = "Current email cannot be the same as the new email.";
            } else if (value !== userEmail) {
                newErrors.currentEmail = "Current email does not match our records.";
            } else {
                delete newErrors.currentEmail;
            }
        }

        if (name === 'newEmail') {
            if (value === modalData.currentEmail) {
                newErrors.newEmail = "New email cannot be the same as the current email.";
            } else if (!validateEmail(value)) {
                newErrors.newEmail = "Invalid email format.";
            } else {
                delete newErrors.newEmail;
            }
        }

        if (name === 'password') {
            if (!validatePassword(value)) {
                newErrors.password = "Password must be at least 6 characters long.";
            } else {
                delete newErrors.password;
            }
        }

        setErrors(newErrors);
    };

    const handleModalSubmit = async () => {
        // Check for any existing field-specific errors
        const fieldErrorsExist = errors.currentEmail || errors.newEmail || errors.password;
        if (fieldErrorsExist) return;

        setLoading(true); // Set loading state to true when the request starts
        setMessage(""); // Clear any previous messages

        try {
            const response = await fetch(`${urlPrefix}/User/modifyEmail`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: userId,
                    email: modalData.newEmail,
                    password: modalData.password,
                }),
            });

            if (response.ok) {
                setErrors({});
                setMessage(
                    <div className="flex items-center text-green-500 bg-green-100 p-3 rounded-md">
                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                        Email updated Successfully!
                    </div>
                );
                setTimeout(() => {
                    setMessage("");
                    onClose(); // Close modal after 1 second
                }, 1000);
            } else {
                const errorData = await response.json();
                let newErrors = {};

                if (errorData.currentEmail) {
                    newErrors.currentEmail = errorData.currentEmail;
                }

                if (errorData.newEmail) {
                    newErrors.newEmail = errorData.newEmail;
                }

                if (errorData.password) {
                    newErrors.password = errorData.password;
                }

                // Set generic error if the specific errors are not returned
                if (Object.keys(newErrors).length === 0) {
                    setMessage(
                        <div className="flex items-center text-red-500 bg-red-100 p-3 rounded-md">
                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                            Error. Please check your credentials and try again.
                        </div>
                    );
                }                
                setErrors(newErrors);
                setTimeout(() => {
                    setMessage("");
                    setErrors("");
                }, 1000)
            }
        } catch (error) {
            console.error('Error updating email:', error);
            setErrors({ form: "An unexpected error occurred. Please try again later." });
            setTimeout(() => {
                setErrors("");
            }, 1000)
        }

        setLoading(false); // Set loading state to false after the request completes
    };

    // Check if any required field is empty
    const isAnyFieldEmpty = !modalData.currentEmail || !modalData.newEmail || !modalData.password;

    // Check if there are any field-specific errors
    const hasFieldErrors = !!(errors.currentEmail || errors.newEmail || errors.password);

    // Determine if the button should be disabled
    const shouldDisableButton = isAnyFieldEmpty || hasFieldErrors;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-xl h-auto min-h-[400px] mx-auto mt-20 z-50 transform transition-transform duration-300 scale-95"
            overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 z-40 flex items-center justify-center"
        >
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Update Email</h2>

            <div className="space-y-4">
                {message && <div className="text-center">{message}</div>}
                {errors.form && <p className="text-red-500 text-sm">{errors.form}</p>}
                <label className="block font-bold">
                    Current Email
                    <input
                        type="email"
                        name="currentEmail"
                        value={modalData.currentEmail}
                        onChange={handleModalChange}
                        autoComplete="off"
                        placeholder="Enter your current email"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.currentEmail ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                    {errors.currentEmail && <span className="text-red-500 text-sm">{errors.currentEmail}</span>}
                </label>
                <label className="block font-bold">
                    New Email
                    <input
                        type="email"
                        name="newEmail"
                        value={modalData.newEmail}
                        onChange={handleModalChange}
                        autoComplete="off"
                        placeholder="Enter your new email"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.newEmail ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                    {errors.newEmail && <span className="text-red-500 text-sm">{errors.newEmail}</span>}
                </label>
                <label className="block font-bold">
                    Password
                    <input
                        type="password"
                        name="password"
                        value={modalData.password}
                        autoComplete="new-password"
                        onChange={handleModalChange}
                        placeholder="Enter your password"
                        className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
                            errors.password ? 'border-red-500' : 'border-gray-300'
                        }`}
                    />
                    {errors.password && <span className="text-red-500 text-sm">{errors.password}</span>}
                </label>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
                <button
                    className={`bg-blue-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg ${
                        shouldDisableButton || loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleModalSubmit}
                    disabled={shouldDisableButton || loading}
                >
                    {loading ? (
                        <span className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                            <span>Updating...</span>
                        </span>
                    ) : (
                        'Update Email'
                    )}
                </button>
                <button
                    className="bg-gray-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-gray-600 hover:shadow-lg"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default EmailUpdateModal;



// import React, { useEffect, useState } from 'react';
// import Modal from 'react-modal';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

// const EmailUpdateModal = ({ isOpen, onClose, modalData, setModalData, userEmail, errors, setErrors, userId, urlPrefix }) => {
//     const [message, setMessage] = useState("");

//     useEffect(() => {
//         if (isOpen) {
//             // Reset modal fields when the modal is opened
//             setModalData({ currentEmail: '', newEmail: '', password: '' });
//             setErrors({});
//             setMessage('');
//         }
//     }, [isOpen]);

//     const validateEmail = (email) => {
//         const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
//         return emailRegex.test(email);
//     };

//     const validatePassword = (password) => {
//         return password.length >= 6; // Example: Password must be at least 6 characters long
//     };

//     const handleModalChange = (e) => {
//         const { name, value } = e.target;
//         setModalData({ ...modalData, [name]: value });

//         let newErrors = { ...errors };

//         // Validate each field based on its name
//         if (name === 'currentEmail') {
//             if (value !== userEmail && value === modalData.newEmail) {
//                 newErrors.currentEmail = "Current email cannot be the same as the new email.";
//             } else if (value !== userEmail) {
//                 newErrors.currentEmail = "Current email does not match our records.";
//             } else {
//                 delete newErrors.currentEmail;
//             }
//         }

//         if (name === 'newEmail') {
//             if (value === modalData.currentEmail) {
//                 newErrors.newEmail = "New email cannot be the same as the current email.";
//             } else if (!validateEmail(value)) {
//                 newErrors.newEmail = "Invalid email format.";
//             } else {
//                 delete newErrors.newEmail;
//             }
//         }

//         if (name === 'password') {
//             if (!validatePassword(value)) {
//                 newErrors.password = "Password must be at least 6 characters long.";
//             } else {
//                 delete newErrors.password;
//             }
//         }

//         setErrors(newErrors);
//     };

//     const handleModalSubmit = async () => {
//         // Check for any existing field-specific errors
//         const fieldErrorsExist = errors.currentEmail || errors.newEmail || errors.password;
//         if (fieldErrorsExist) return;

//         try {
//             const response = await fetch(`${urlPrefix}/User/modifyEmail`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     "Authorization": localStorage.getItem("token")
//                 },
//                 body: JSON.stringify({
//                     id: userId,
//                     email: modalData.newEmail,
//                     password: modalData.password,
//                 }),
//             });

//             if (response.ok) {
//                 setErrors({});
//                 setMessage(
//                     <div className="flex items-center text-green-500 bg-green-100 p-3 rounded-md">
//                         <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
//                         Email updated successfully, check your email address!
//                     </div>
//                 );
//                 // localStorage.removeItem("userId");
//                 // localStorage.removeItem("token");
//                 setTimeout(() => {
//                     onClose(); // Close modal after 5 seconds
//                 }, 1000);
//             } else {
//                 const errorData = await response.json();
//                 let newErrors = {};

//                 if (errorData.currentEmail) {
//                     newErrors.currentEmail = errorData.currentEmail;
//                 }

//                 if (errorData.newEmail) {
//                     newErrors.newEmail = errorData.newEmail;
//                 }

//                 if (errorData.password) {
//                     newErrors.password = errorData.password;
//                 }

//                 // Set generic error if the specific errors are not returned
//                 if (Object.keys(newErrors).length === 0) {
//                     setMessage(
//                         <div className="flex items-center text-red-500 bg-red-100 p-3 rounded-md">
//                             <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
//                             Error. Please check your credentials and try again.
//                         </div>
//                     );
//                 }

//                 setErrors(newErrors);
//             }
//         } catch (error) {
//             console.error('Error updating email:', error);
//             setErrors({ form: "An unexpected error occurred. Please try again later." });
//         }
//     };

//     // Check if any required field is empty
//     const isAnyFieldEmpty = !modalData.currentEmail || !modalData.newEmail || !modalData.password;

//     // Check if there are any field-specific errors
//     const hasFieldErrors = !!(errors.currentEmail || errors.newEmail || errors.password);

//     // Determine if the button should be disabled
//     const shouldDisableButton = isAnyFieldEmpty || hasFieldErrors;

//     return (
//         <Modal
//             isOpen={isOpen}
//             onRequestClose={onClose}
//             className="bg-white p-8 rounded-xl shadow-2xl w-full max-w-xl h-auto min-h-[400px] mx-auto mt-20 z-50 transform transition-transform duration-300 scale-95"
//             overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50 z-40 flex items-center justify-center"
//         >
//             <h2 className="text-3xl font-bold text-gray-900 mb-6">Update Email</h2>

//             <div className="space-y-4">
//                 {message && <div className="text-center">{message}</div>}
//                 {errors.form && <p className="text-red-500 text-sm">{errors.form}</p>}
//                 <label className="block font-bold">
//                     Current Email
//                     <input
//                         type="email"
//                         name="currentEmail"
//                         value={modalData.currentEmail}
//                         onChange={handleModalChange}
//                         autoComplete="off"
//                         placeholder="Enter your current email"
//                         className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
//                             errors.currentEmail ? 'border-red-500' : 'border-gray-300'
//                         }`}
//                     />
//                     {errors.currentEmail && <span className="text-red-500 text-sm">{errors.currentEmail}</span>}
//                 </label>
//                 <label className="block font-bold">
//                     New Email
//                     <input
//                         type="email"
//                         name="newEmail"
//                         value={modalData.newEmail}
//                         onChange={handleModalChange}
//                         autoComplete="off"
//                         placeholder="Enter your new email"
//                         className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
//                             errors.newEmail ? 'border-red-500' : 'border-gray-300'
//                         }`}
//                     />
//                     {errors.newEmail && <span className="text-red-500 text-sm">{errors.newEmail}</span>}
//                 </label>
//                 <label className="block font-bold">
//                     Password
//                     <input
//                         type="password"
//                         name="password"
//                         value={modalData.password}
//                         autoComplete="new-password"
//                         onChange={handleModalChange}
//                         placeholder="Enter your password"
//                         className={`mt-1 p-3 border rounded-lg w-full transition-all duration-300 ease-in-out ${
//                             errors.password ? 'border-red-500' : 'border-gray-300'
//                         }`}
//                     />
//                     {errors.password && <span className="text-red-500 text-sm">{errors.password}</span>}
//                 </label>
//             </div>
//             <div className="flex justify-end space-x-4 mt-6">
//                 <button
//                     className={`bg-blue-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-blue-600 hover:shadow-lg ${
//                         shouldDisableButton ? 'opacity-50 cursor-not-allowed' : ''
//                     }`}
//                     onClick={handleModalSubmit}
//                     disabled={shouldDisableButton}
//                 >
//                     Update Email
//                 </button>
//                 <button
//                     className="bg-gray-500 text-white p-3 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-gray-600 hover:shadow-lg"
//                     onClick={onClose}
//                 >
//                     Cancel
//                 </button>
//             </div>
//         </Modal>
//     );
// };

// export default EmailUpdateModal;