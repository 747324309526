// components/MetricsCard.js
import React from 'react';

const MetricsCard = ({ icon: Icon, color, label, value }) => {
  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center text-center hover:shadow-2xl transition-shadow duration-300">
      <Icon className={`text-3xl md:text-4xl text-${color}-500 mb-1 md:mb-2`} />
      <div className="text-gray-700 mb-1 md:mb-2">{label}</div>
      <div className="text-2xl md:text-3xl font-bold text-gray-800">{value}</div>
    </div>
  );
};

export default MetricsCard;
