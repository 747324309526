import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaBars, FaHome, FaUsers, FaBook, FaChevronDown, FaChartBar, FaArchive, FaPlus, FaCog,
  FaUserEdit, FaBell, FaSlidersH, FaChevronRight
} from 'react-icons/fa';

const SideBar = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isClassesExpanded, setIsClassesExpanded] = useState(false);
  const [isUsersExpanded, setIsUsersExpanded] = useState(false);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    if (isExpanded) {
      setIsClassesExpanded(false);
      setIsUsersExpanded(false);
      setIsSettingsExpanded(false);
    }
  };

  const toggleClasses = () => {
    setIsClassesExpanded(!isClassesExpanded);
    setIsExpanded(true);
  };

  const toggleUsers = () => {
    setIsUsersExpanded(!isUsersExpanded);
    setIsExpanded(true);
  };

  const toggleSettings = () => {
    setIsSettingsExpanded(!isSettingsExpanded);
    setIsExpanded(true);
  };

  return (
    <aside className={`bg-gradient-to-b from-[#1a1a1a] to-[#4d4d4d] text-[#ffffff] flex flex-col ${isExpanded ? 'w-48 md:w-64' : 'w-16 md:w-20'} min-h-screen transition-all duration-500`}>
      <div className="flex items-center justify-between p-4 border-b border-[#595959]">
        {isExpanded && <h1 className="text-lg md:text-2xl font-bold text-[#f2f2f2]">Admin</h1>}
        <button onClick={toggleSidebar}>
          <FaBars className="text-xl md:text-2xl text-[#cccccc] hover:text-[#ffffff] transition duration-200" />
        </button>
      </div>
      <div className="flex-grow overflow-y-auto mt-3">
        <nav>
          <ul>
            <li className="mb-1 md:mb-2">
              <button
                onClick={() => navigate('/admin')}
                className={`w-full text-left flex items-center ${!isExpanded ? 'justify-center' : ''} px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300`}
              >
                <FaHome className="text-lg md:text-xl text-[#cccccc]" />
                {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Home</span>}
              </button>
            </li>
            <li className="mb-1 md:mb-2">
              <button
                onClick={toggleUsers}
                className={`w-full text-left flex items-center ${!isExpanded ? 'justify-center' : ''} px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300`}
              >
                <FaUsers className="text-lg md:text-xl text-[#cccccc]" />
                {isExpanded && (
                  <>
                    <span className="ml-2 md:ml-4 text-[#cccccc]">Users</span>
                    {isUsersExpanded ? <FaChevronDown className="ml-auto text-[#cccccc]" /> : <FaChevronRight className="ml-auto text-[#cccccc]" />}
                  </>
                )}
              </button>
            </li>
            {isUsersExpanded && (
              <div className="ml-4 md:ml-8">
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/users')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaUsers className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Users</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/usersAnalysis')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaChartBar className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Users Analysis</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/archivedUsers')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaArchive className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Archived Users</span>}
                  </button>
                </li>
              </div>
            )}
            <li className="mb-1 md:mb-2">
              <button
                onClick={toggleClasses}
                className={`w-full text-left flex items-center ${!isExpanded ? 'justify-center' : ''} px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300`}
              >
                <FaBook className="text-lg md:text-xl text-[#cccccc]" />
                {isExpanded && (
                  <>
                    <span className="ml-2 md:ml-4 text-[#cccccc]">Classes</span>
                    {isClassesExpanded ? <FaChevronDown className="ml-auto text-[#cccccc]" /> : <FaChevronRight className="ml-auto text-[#cccccc]" />}
                  </>
                )}
              </button>
            </li>
            {isClassesExpanded && (
              <div className="ml-4 md:ml-8">
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/classes')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaBook className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Classes</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/createClass')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaPlus className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Create Class</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/classesAnalysis')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaChartBar className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Class Analysis</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/archivedClasses')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaArchive className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Archived Classes</span>}
                  </button>
                </li>
              </div>
            )}
            <li className="mb-1 md:mb-2">
              <button
                onClick={toggleSettings}
                className={`w-full text-left flex items-center ${!isExpanded ? 'justify-center' : ''} px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300`}
              >
                <FaCog className="text-lg md:text-xl text-[#cccccc]" />
                {isExpanded && (
                  <>
                    <span className="ml-2 md:ml-4 text-[#cccccc]">Settings</span>
                    {isSettingsExpanded ? <FaChevronDown className="ml-auto text-[#cccccc]" /> : <FaChevronRight className="ml-auto text-[#cccccc]" />}
                  </>
                )}
              </button>
            </li>
            {isSettingsExpanded && (
              <div className="ml-4 md:ml-8">
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/editProfile')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaUserEdit className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Edit Profile</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/notificationSettings')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaBell className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Notification</span>}
                  </button>
                </li>
                <li className="mb-1 md:mb-2">
                  <button
                    onClick={() => navigate('/admin/configureUi')}
                    className="w-full text-left flex items-center px-2 md:px-4 py-2 rounded-md hover:bg-[#505050] transition duration-300"
                  >
                    <FaSlidersH className="text-lg md:text-xl text-[#cccccc]" />
                    {isExpanded && <span className="ml-2 md:ml-4 text-[#cccccc]">Configure UI</span>}
                  </button>
                </li>
              </div>
            )}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;