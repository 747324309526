import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.onerror = (e) => {
        console.error('Video failed to load', e);
        videoElement.style.display = 'none'; // Hide video if it fails to load
      };
    }
  }, []);

  return (
    <div className="relative mt-24 text-white py-20 overflow-hidden custom-hero-height flex items-center justify-center">
      <style>{`
        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in-down {
          animation: fadeInDown 1s ease-out forwards;
        }

        .animate-fade-in-up {
          animation: fadeInUp 1s ease-out forwards;
        }

        .delay-200 {
          animation-delay: 0.2s;
        }

        .delay-500 {
          animation-delay: 0.5s;
        }

        .custom-hero-height {
          height: 100vh; /* Set the height as needed */
        }
      `}</style>
      <div className="absolute inset-0 w-full h-full opacity-20"></div>
      <div className="container mx-auto flex flex-col items-center justify-center text-center relative z-10">
        <h1 className="text-6xl font-extrabold mb-4 animate-fade-in-down text-blue-600">Welcome to Our Big Family</h1>
        <p className="text-2xl mb-6 animate-fade-in-up text-white">Empowering Education with Modern Solutions</p>
        <p className="text-lg mb-6 animate-fade-in-up delay-200 text-white">Join thousands of learners who are achieving their educational goals with us.</p>
        <div className="flex flex-col items-center">
          <button
            className="text-white bg-blue-700 hover:bg-blue-900 px-6 py-3 rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => navigate('/register')}
          >
            Get Started
          </button>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-blue-600 to-transparent"></div>
      <video className="absolute top-0 left-0 w-full h-full object-cover opacity-95" autoPlay loop muted>
        <source src="/vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Hero;