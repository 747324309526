import React from 'react';
import ClassInfo from './ClassInfo';
import { urlPrefix } from '../../../../../Settings';

const createAccount = async (courseData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error('No token found');
    return;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({
      accountImage: courseData.photoURL ? courseData.photoURL : null,
      name: courseData.classTitle,
      description: courseData.classDescription,
      maxCapacity: courseData.classCapacity,
      onboardingLink: courseData.classLink.url,
      isVisible: courseData.classVisible
    })
  };

  try {
    const url = `${urlPrefix}/Account/addAccount?accountId=${1}`;
    const response = await fetch(url, requestOptions)

    if (!response.ok) {
      throw new Error('Failed to add account');
    }

    const result = await response.json();
    console.log('Account added successfully:', result);
  } catch (error) {
    console.error('Error adding account:', error);
  }
};

const CreateClass = () => {
  return (
    <div>
      <ClassInfo
        initialImage=""
        initialTitle=""
        initialDescription=""
        initialLink={{ url: '', label: '' }}
        initialCapacity={0}
        initialVisibility={true}
        handleAction={createAccount}
      />
    </div>
  );
};

export default CreateClass;