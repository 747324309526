import React from 'react';
import { useNavigate } from 'react-router-dom';
import a1 from '../../images/c1.webp';
import a2 from '../../images/c2.webp';
import a3 from '../../images/c3.webp';

const caseStudies = [
  {
    id: 7,
    img: a1,
    title: 'Case Study One: Construction Company',
    description: 'How our product helped a construction company improve efficiency.',
  },
  {
    id: 8,
    img: a2,
    title: 'Case Study Two: Training Center',
    description: 'Implementation of our product in a training center for better learning outcomes.',
  },
  {
    id: 9,
    img: a3,
    title: 'Case Study Three: Global Industry',
    description: 'Global industry success story with our innovative solutions.',
  },
];

const CaseStudy = () => {
  const navigate = useNavigate(); // Use navigate for routing

  return (
    <section id="case-studies" className="p-20">
      <h2 className="text-3xl font-bold mb-8 text-center">Case Studies</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {caseStudies.map(caseStudy => (
          <div
            key={caseStudy.id}
            className="p-6 rounded-lg shadow-lg hover:shadow-xl flex flex-col justify-between hover:bg-slate-100 transition-transform duration-300 transform hover:-translate-y-2"
          >
            <div>
              <img src={caseStudy.img} alt={caseStudy.title} className="mb-4 w-full h-80 object-cover rounded" />
              <h3 className="text-xl font-bold mb-2">{caseStudy.title}</h3>
              <p>{caseStudy.description}</p>
            </div>
            <button
              onClick={() => navigate(`/learn-more-case-study/${caseStudy.id}`)} // Use navigate to go to the case study page
              className="text-blue-500 hover:underline mt-4 self-end"
            >
              Learn More <i className="bi-chevron-right small ms-1"></i>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CaseStudy;