import React, { useEffect, useState } from 'react';
import { urlPrefix } from '../../../Settings';
import JoinFormModal from '../JoinFormModal';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const MostPopularAccounts = () => {
  const [popularAccounts, setPopularAccounts] = useState([]);
  const [userAccounts, setUserAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [formUrl, setFormUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState('');     // State for error message
  const [loading, setLoading] = useState(true); // State for fetching data
  const [joiningStatus, setJoiningStatus] = useState({}); // Object to track joining status for each account
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(JSON.parse(storedUserId));
    }
  }, []);

  const fetchPopularAccounts = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/visible`, requestOptions);
      const data = await response.json();

      if (data && data.length > 0) {
        setPopularAccounts(data);
      } else {
        console.error('No accounts found or data format is incorrect');
      }
    } catch (error) {
      console.error('Error fetching popular accounts:', error);
    } finally {
      setLoading(false); // Hide spinner after data is loaded
    }
  };

  const fetchUserAccounts = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/users/accounts`, requestOptions);
      const data = await response.json();

      if (data && data.length > 0) {
        setUserAccounts(data);
      } else {
        console.error('No accounts found or data format is incorrect');
      }
    } catch (error) {
      console.error('Error fetching user accounts:', error);
    }
  };

  useEffect(() => {
    fetchPopularAccounts();
  }, []);

  useEffect(() => {
    if (userId) {
      fetchUserAccounts();
    }
  }, [userId]);

  const handleJoinClick = async (account) => {
    const accountId = account.id;

    if (!userId) {
      setErrorMessage('User not logged in. Please log in first.');
      hideMessagesAfterTimeout();
      return;
    }

    setJoiningStatus((prevStatus) => ({
      ...prevStatus,
      [accountId]: true, // Set the loading state for the clicked account
    }));

    const payload = {
      userId: parseInt(userId, 10),
      accountId,
      userAccountRoleEnum: 8,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token'),
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(`${urlPrefix}/Account/addUser`, requestOptions);

      if (response.ok) {
        setSuccessMessage('Successfully joined the account!');
        // Add the account to the userAccounts state
        setUserAccounts((prevAccounts) => [...prevAccounts, account]);
        hideMessagesAfterTimeout();
        fetchPopularAccounts(); // Refresh popular accounts
      } else {
        setErrorMessage('Failed to join account. Please try again.');
      }
      hideMessagesAfterTimeout();
    } catch (error) {
      console.error('Error joining account:', error);
      setErrorMessage('An error occurred. Please try again.');
      hideMessagesAfterTimeout();
    } finally {
      setJoiningStatus((prevStatus) => ({
        ...prevStatus,
        [accountId]: false, // Reset the loading state for the clicked account
      }));
    }
  };


  const hideMessagesAfterTimeout = () => {
    setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 2000);  // Hide messages after 3 seconds
  };

  const handleSeeAllClick = () => {
    navigate('/user/all-accounts');
  };

  // Filter out accounts that are already in userAccounts
  const accountsToShow = popularAccounts.filter(account =>
    !userAccounts.some(userAccount => userAccount.id === account.id)
  );
  console.log(userAccounts);
  console.log(accountsToShow);
  console.log(popularAccounts);

  return (
    <div className="mt-16 mb-1">
      <h1 className="flex text-4xl text-sky-900 ml-3 mb-3">Classes To Enroll</h1>

      {/* Success and Error Messages */}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            {successMessage}
          </span>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {errorMessage}
          </span>
        </div>
      )}

      {/* Loading Spinner */}
      {loading ? (
        <div className="flex justify-center mt-10">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-blue-600" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {accountsToShow.slice(0, 12).map((account, index) => (
            <div
              className="p-1 h-full w-full rounded-lg shadow-md hover:shadow-xl flex flex-col justify-between transition-transform duration-300 transform hover:-translate-y-2 hover:bg-slate-300 cursor-pointer border border-solid border-blue-950"
              key={index}
            >
              <div className="flex-grow">
                <div
                  className="mb-1 w-full h-40 bg-cover bg-center rounded"
                  style={{ backgroundImage: `url(${account.accountImage || ''})` }}
                ></div>
                <div className="bg-slate-800 rounded">
                  <div className="flex flex-col items-start px-2 pt-1">
                    <h3 className="text-xl font-bold mb-1 text-white">{account.name}</h3>
                    {account.description && <p className="text-sm text-white py-2">{account.description}</p>}
                  </div>
                  {/* <div className="flex justify-end items-stretch p-2">
                    {userAccounts.some((userAccount) => userAccount.id === account.id) ? (
                      <button
                        className="btn mt-4 bg-green-500 text-white px-3 py-2 rounded-lg shadow-md flex justify-center items-center cursor-not-allowed"
                        disabled
                      >
                        Enrolled
                      </button>
                    ) : (
                      <button
                        onClick={() => handleJoinClick(account)}
                        className={`btn mt-4 text-white px-3 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out flex justify-center items-center 
                            ${joiningStatus[account.id] ? 'bg-blue-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600 hover:shadow-lg'}`}
                        disabled={joiningStatus[account.id]}
                        style={{ pointerEvents: joiningStatus[account.id] ? 'none' : 'auto' }}
                      >
                        {joiningStatus[account.id] ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          'Join'
                        )}
                      </button>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {accountsToShow.length > 12 && (
        <div className="flex justify-center mt-4">
          <button onClick={handleSeeAllClick} className="btn btn-outline-primary">
            See All Accounts
          </button>
        </div>
      )}

      {selectedAccount && (
        <JoinFormModal account={selectedAccount} onClose={() => setSelectedAccount(null)} formUrl={formUrl} />
      )}
    </div>
  );
};

export default MostPopularAccounts;

