import React from 'react';
import { FaUsers, FaUserCheck, FaUserClock } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const UsersAnalysis = () => {
  const totalUsers = 1234;
  const activeUsers = 567;
  const pendingUsers = 89;

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'User Sign-ups',
        data: [12, 19, 3, 5, 2, 3, 7],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Sign-ups Over the Past Months',
      },
    },
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h2 className="text-xl md:text-2xl font-bold mb-4">Users Analysis</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-4 md:mb-6">
        <div className="bg-gradient-to-r from-blue-500 to-blue-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaUsers className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Total Users</div>
            <div className="text-xl md:text-2xl font-bold">{totalUsers}</div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-green-500 to-green-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaUserCheck className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Active Users</div>
            <div className="text-xl md:text-2xl font-bold">{activeUsers}</div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-yellow-500 to-yellow-700 text-white p-4 md:p-6 rounded-lg shadow-md flex items-center">
          <FaUserClock className="text-3xl md:text-4xl mr-4" />
          <div>
            <div className="text-lg md:text-xl">Pending Users</div>
            <div className="text-xl md:text-2xl font-bold">{pendingUsers}</div>
          </div>
        </div>
      </div>
      <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default UsersAnalysis;
