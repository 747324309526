import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id from the URL

const features = [
  {
    id: 1,
    category: 'features',
    img: require('../../images/cols.webp'),
    alt: 'Real-time Collaboration',
    title: 'Real-time Collaboration',
    description: 'Our real-time collaboration tools enable seamless teamwork across different locations.',
    details: [
      'Instant Messaging: Communicate with your team instantly through our secure messaging platform.',
      'File Sharing: Share documents, presentations, and other files with ease.',
      'Collaborative Document Editing: Edit documents simultaneously with team members in real-time, ensuring everyone stays on the same page.',
      'Video Conferencing: Hold virtual meetings with high-quality video and audio, connecting teams across different locations.',
      'Task Management: Assign tasks, set deadlines, and track progress with our integrated task management system.',
      'Project Timelines: Visualize your project’s timeline and milestones for a clear understanding of what’s ahead.',
    ],
  },
  {
    id: 2,
    category: 'features',
    img: require('../../images/analytics.webp'),
    alt: 'Advanced Analytics',
    title: 'Advanced Analytics',
    description: 'Track performance and growth with our advanced analytics tools, helping you make data-driven decisions.',
    details: [
      'Comprehensive Dashboards: Get an overview of your performance with our intuitive dashboards, providing real-time data at your fingertips.',
      'Customizable Reports: Generate detailed reports tailored to your specific needs, with the ability to filter by metrics, departments, and timelines.',
      'Real-time Data: Access up-to-date information with our real-time data analytics, ensuring you always have the latest insights.',
      'Predictive Analytics: Use predictive analytics to forecast future trends, helping you stay ahead of the competition.',
      'KPI Tracking: Track key performance indicators (KPIs) to measure your progress and ensure you’re meeting your goals.',
      'Benchmarking: Compare your performance against industry standards to understand where you stand in the market.',
    ],
  },
  {
    id: 3,
    category: 'features',
    img: require('../../images/workflows.webp'),
    alt: 'Customizable Workflows',
    title: 'Customizable Workflows',
    description: 'Adapt workflows to fit your unique business needs, providing flexibility and efficiency.',
    details: [
      'Process Configuration: Customize workflows to align with your business processes, ensuring you work the way you want to.',
      'Automation: Automate routine tasks to save time and reduce the risk of human error, increasing overall efficiency.',
      'Integration: Seamlessly integrate our tools with your existing systems, ensuring a smooth transition and minimal disruption.',
      'Task Management: Manage tasks effectively with built-in task management tools, helping you keep track of assignments and deadlines.',
      'Collaboration: Foster collaboration within your team with built-in communication tools, allowing for easy information sharing and coordination.',
      'Workflow Analysis: Analyze and optimize your workflows to identify bottlenecks and improve overall productivity.',
    ],
  },
];

const LearnMoreFeature = () => {
  const { id } = useParams(); // Get the id from the URL params
  const feature = features.find(item => item.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component loads
  }, []);

  if (!feature) {
    return <div>Feature not found</div>;
  }

  return (
    <section className="p-16 mt-20 bg-gray-50 min-h-screen">
      <div className="container mx-auto">
        <h2 className="text-5xl font-bold mb-8 text-center text-blue-700">{feature.title}</h2>
        <div className="flex flex-col md:flex-row mb-8">
          <div className="md:w-1/4">
            <img src={feature.img} alt={feature.alt} className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="md:w-1/2 md:pl-10 mt-6 md:mt-0">
            <p className="text-gray-700 text-lg">{feature.description}</p>
            <ul className="list-disc list-inside mt-4 text-gray-700">
              {feature.details.map((detail, index) => (
                <li key={index} className="mt-2">{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreFeature;