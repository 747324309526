import React from 'react';
import { useNavigate } from 'react-router-dom';

const DiscoverMore = () => {
  const navigate = useNavigate();

  return (
    <section id="discover-more" className="p-16 text-center">
      <h2 className="text-3xl font-bold mb-8">Discover More</h2>
      <p className="text-xl mb-8">Find out more about how our product can benefit your business.</p>
      <button
        onClick={() => navigate('/learn-more-discover-more')}
        className="bg-blue-500 text-white px-6 py-3 rounded"
      >
        Learn More
      </button>
    </section>
  );
};

export default DiscoverMore;